// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-index-en-js": () => import("../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-es-js": () => import("../src/pages/contact/index.es.js" /* webpackChunkName: "component---src-pages-contact-index-es-js" */),
  "component---src-pages-index-en-js": () => import("../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

